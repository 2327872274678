ion-fab-button[title] {
    position: relative;
}

ion-fab-button[title]::after {
    position: absolute;
    content: attr(title);
    z-index: 1;
    right: 55px;
    bottom: 6px;
    background-color: white;
    padding: 9px;
    border-radius: 15px;
    color: var(--ion-color-primary);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}