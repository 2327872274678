ion-content ion-toolbar {
  --background: translucent;
}

@media (max-width: 576px) {
  .hide-sm-down {
    display: none;
  }
}

.permanent-message {
  font-weight: 500;
}